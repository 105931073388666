"use client";
import { useState } from "react";
import { FileSchema } from "~/types/backend";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { ScrollArea } from "../ui/scroll-area";
import { FileDropzone } from "./file-dropzone";
import { FileTable } from "./file-table";

export function useFileSelector() {
  const [selectedFile, setSelectedFile] = useState<FileSchema | undefined>();
  const [open, setOpen] = useState(false);
  return { selectedFile, setSelectedFile, open, setOpen };
}

export function FileSelector(props: {
  selectedFile?: FileSchema;
  setSelectedFile: (file: FileSchema | undefined) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { setSelectedFile, open, setOpen } = props;
  const [selectedFileLocal, setSelectedFileLocal] = useState<
    FileSchema | undefined
  >();
  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="max-w-[900px]">
          <DialogHeader>
            <DialogTitle>Select a file</DialogTitle>
          </DialogHeader>
          <FileDropzone />
          <ScrollArea className="max-h-[300px]">
            <FileTable
              selectedFile={selectedFileLocal}
              onSelect={setSelectedFileLocal}
            />
          </ScrollArea>
          <div className="flex justify-end gap-2">
            <Button
              onClick={() => {
                setOpen(false);
                setSelectedFile(undefined);
              }}
              variant={"outline"}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
                setSelectedFile(selectedFileLocal);
              }}
              disabled={!selectedFileLocal}
            >
              Select
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
