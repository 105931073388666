"use client";
import {
  IconArrowBack,
  IconArrowForward,
  IconBold,
  IconBrandYoutube,
  IconH1,
  IconH2,
  IconH3,
  IconItalic,
  IconLink,
  IconList,
  IconListNumbers,
  IconStrikethrough,
} from "@tabler/icons-react";

import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Youtube from "@tiptap/extension-youtube";
import { Editor, EditorContent, JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Button } from "../ui/button";

import { cn } from "~/lib/utils";

import { Image as IconImage } from "lucide-react";
import { useState } from "react";
import { FileSelector } from "../file/file-selector";
import { ScrollArea } from "../ui/scroll-area";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { ImageResize } from "./image-resize-extension";
import "./tiptap.css";

function MenuButton({
  onClick,
  disabled,
  active,
  className = "",
  children,
  tooltip,
}: {
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
  className?: string;
  tooltip?: string;
  children: React.ReactNode;
}) {
  return (
    <Tooltip open={tooltip ? undefined : false} delayDuration={1000}>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          onClick={onClick}
          disabled={disabled}
          className={cn(
            active ? "bg-primary" : "bg-white",
            "mr-1 px-1 ",
            className
          )}
          tabIndex={-1}
        >
          {children}
        </Button>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
}

function MenuButtonLink({ editor }: { editor: Editor }) {
  const addLink = () => {
    const previousUrl = editor.getAttributes("link").href;
    const url = prompt("Enter URL", previousUrl);

    if (url) {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    }
  };
  return (
    <MenuButton
      onClick={() => {
        addLink();
      }}
      tooltip="Add Link"
    >
      <IconLink size={"20px"} />
    </MenuButton>
  );
}

function MenuButtonBold({ editor }: { editor: Editor }) {
  return (
    <MenuButton
      onClick={() => editor.chain().focus().toggleBold().run()}
      disabled={!editor.can().chain().focus().toggleBold().run()}
      active={editor.isActive("bold")}
      tooltip="Bold"
    >
      <IconBold size={"20px"} />
    </MenuButton>
  );
}

function MenuButtonItalic({ editor }: { editor: Editor }) {
  return (
    <MenuButton
      onClick={() => editor.chain().focus().toggleItalic().run()}
      disabled={!editor.can().chain().focus().toggleItalic().run()}
      active={editor.isActive("italic")}
      tooltip="Italic"
    >
      <IconItalic size={"20px"} />
    </MenuButton>
  );
}

function MenuButtonStrike({ editor }: { editor: Editor }) {
  return (
    <MenuButton
      onClick={() => editor.chain().focus().toggleStrike().run()}
      disabled={!editor.can().chain().focus().toggleStrike().run()}
      className={editor.isActive("strike") ? "bg-primary" : ""}
      tooltip="Strike"
    >
      <IconStrikethrough size={"20px"} />
    </MenuButton>
  );
}
function MenuButtonHeading1({ editor }: { editor: Editor }) {
  return (
    <MenuButton
      onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
      disabled={!editor.can().chain().focus().toggleStrike().run()}
      className={editor.isActive("heading", { level: 1 }) ? "bg-primary" : ""}
      tooltip="Heading 1"
    >
      <IconH1 size={"20px"} />
    </MenuButton>
  );
}

function MenuButtonHeading2({ editor }: { editor: Editor }) {
  return (
    <MenuButton
      onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
      disabled={!editor.can().chain().focus().toggleStrike().run()}
      className={editor.isActive("heading", { level: 2 }) ? "bg-primary" : ""}
      tooltip="Heading 2"
    >
      <IconH2 size={"20px"} />
    </MenuButton>
  );
}

function MenuButtonHeading3({ editor }: { editor: Editor }) {
  return (
    <MenuButton
      onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
      disabled={!editor.can().chain().focus().toggleStrike().run()}
      className={editor.isActive("heading", { level: 3 }) ? "bg-primary" : ""}
      tooltip="Heading 3"
    >
      <IconH3 size={"20px"} />
    </MenuButton>
  );
}

function MenuButtonBulletList({ editor }: { editor: Editor }) {
  return (
    <MenuButton
      onClick={() => editor.chain().focus().toggleBulletList().run()}
      disabled={!editor.can().chain().focus().toggleBulletList().run()}
      active={editor.isActive("bulletList")}
      tooltip="Bullet List"
    >
      <IconList size={"20px"} />
    </MenuButton>
  );
}

function MenuButtonOrderedList({ editor }: { editor: Editor }) {
  return (
    <MenuButton
      onClick={() => editor.chain().focus().toggleOrderedList().run()}
      disabled={!editor.can().chain().focus().toggleOrderedList().run()}
      active={editor.isActive("orderedList")}
      tooltip="Ordered List"
    >
      <IconListNumbers size={"20px"} />
    </MenuButton>
  );
}

function MenuButtonUndo({ editor }: { editor: Editor }) {
  return (
    <MenuButton
      onClick={() => editor.chain().focus().undo().run()}
      disabled={!editor.can().chain().focus().undo().run()}
      className="ml-2"
      tooltip="Undo"
    >
      <IconArrowBack size={"20px"} />
    </MenuButton>
  );
}

function MenuButtonRedo({ editor }: { editor: Editor }) {
  return (
    <MenuButton
      onClick={() => editor.chain().focus().redo().run()}
      disabled={!editor.can().chain().focus().redo().run()}
      tooltip="Redo"
    >
      <IconArrowForward size={"20px"} />
    </MenuButton>
  );
}

function MenuButtonAddImage({ editor }: { editor: Editor }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <FileSelector
        setSelectedFile={(file) => {
          setOpen(false);
          if (!file || !file.permanent_url) {
            return;
          }
          editor.chain().focus().setImage({ src: file.permanent_url }).run();
        }}
        open={open}
        setOpen={setOpen}
      />
      <MenuButton
        onClick={() => {
          setOpen(true);
        }}
        tooltip="Add Image"
      >
        <IconImage size={"20px"} />
      </MenuButton>
    </>
  );
}

function MenuButtonAddYoutube({ editor }: { editor: Editor }) {
  const addYoutubeVideo = () => {
    const url = prompt("Enter YouTube URL");

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
      });
    }
  };
  return (
    <>
      <MenuButton
        onClick={() => {
          addYoutubeVideo();
        }}
        tooltip="Add YouTube Video"
      >
        <IconBrandYoutube size={"20px"} />
      </MenuButton>
    </>
  );
}

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      <MenuButtonBold editor={editor} />
      <MenuButtonItalic editor={editor} />
      <MenuButtonStrike editor={editor} />
      <MenuButtonBulletList editor={editor} />
      <MenuButtonOrderedList editor={editor} />
      <MenuButtonHeading1 editor={editor} />
      <MenuButtonHeading2 editor={editor} />
      <MenuButtonHeading3 editor={editor} />
      <MenuButtonLink editor={editor} />
      <MenuButtonAddImage editor={editor} />
      <MenuButtonAddYoutube editor={editor} />
      <MenuButtonUndo editor={editor} />
      <MenuButtonRedo editor={editor} />
    </>
  );
};

const extensions = [
  StarterKit,
  Image,
  ImageResize,
  Link,
  Youtube.configure({
    controls: false,
    nocookie: true,
  }),
];

export default function TipTap({
  content,
  setContent,
  className,
  contentClassName = "editorcontent",
  onChange,
  onBlur,
  name,
}: {
  content: JSONContent;
  setContent?: (jsonContent: JSONContent) => void;
  className?: string;
  contentClassName?: string;
  onChange?: (event: { target: { value: JSONContent; name?: string } }) => void;
  onBlur?: (event: { target: { value: JSONContent } }) => void;
  name?: string;
}) {
  const editor = useEditor({
    extensions: extensions,
    content: content,
    onUpdate({ editor }) {
      const updatedContent = editor.getJSON();
      if (setContent) {
        setContent(updatedContent);
      }
      if (onChange) {
        console.log(updatedContent);
        onChange({ target: { value: updatedContent, name: name } });
      }
    },
    onBlur: ({ editor }) => {
      if (onBlur) {
        const updatedContent = editor.getJSON();
        onBlur({ target: { value: updatedContent } });
      }
    },
  });
  return (
    <div
      className={cn(
        "border border-input bg-background rounded-lg flex-grow w-full",
        contentClassName
      )}
    >
      <div className="pl-1 pt-1 ">
        <MenuBar editor={editor} />
      </div>

      <ScrollArea classNameViewPort="max-h-[500px]">
        <EditorContent
          editor={editor}
          className={cn(
            "focus-visible:outline-none p-2 min-h-[150px] flex flex-col",
            className
          )}
        />
      </ScrollArea>
    </div>
  );
}
