import { queryFn } from "~/lib/client";
import { FileSchemaListResponse } from "~/types/backend";

export function getFileListQuery(
  access_token: string | undefined = "",
  context_uuid: string | undefined = "default"
) {
  return {
    queryKey: ["files"],
    queryFn: queryFn<FileSchemaListResponse>(
      "GET",
      `/core/files/context-uuid/${context_uuid}`,
      access_token
    ),
    enabled: access_token !== undefined,
  };
}
