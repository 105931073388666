"use client";

import { useQuery } from "@tanstack/react-query";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useAccessToken } from "~/features/auth/api/auth-hooks";
import { apiClient, constructHeaders } from "~/lib/client";
import { FileSchemaListResponse } from "~/types/backend";
import { getFileListQuery } from "./file-querries";

export function useGetFileList(context_uuid: string = "default") {
  const accessToken = useAccessToken();
  return useQuery(getFileListQuery(accessToken, context_uuid));
}
interface UploadProgress {
  file: File;
  progress: number;
}

export const uploadFiles = async (
  files: FileList,
  access_token: string,
  context_uuid: string = "default",
  onProgress: (progress: UploadProgress) => void,
  isPrivate?: boolean
) => {
  const uploadPromises: Promise<AxiosResponse>[] = [];

  for (const file of files) {
    const formData = new FormData();
    formData.append("file", file);

    const uploadPromise = uploadFile(
      file,
      access_token,
      context_uuid,
      onProgress,
      isPrivate
    );

    uploadPromises.push(uploadPromise);
  }

  return Promise.all(uploadPromises);
};

export const uploadFile = async (
  file: File,
  access_token: string,
  context_uuid: string = "default",
  onProgress: (progress: UploadProgress) => void,
  isPrivate?: boolean
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("context_uuid", context_uuid);
  formData.append("public", isPrivate ? "false" : "true");
  return apiClient.post(`/core/files`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${access_token || ""}`,
    },
    onUploadProgress: (progressEvent) => {
      const progress = Math.round(
        (progressEvent.loaded * 100) / (progressEvent.total || 1)
      );
      onProgress({ file, progress });
    },
  });
};

export type TUploadFilePayload = {
  file: File;
  context_uuid: string;
  onProgress: (progress: UploadProgress) => void;
  isPrivate?: boolean;
};

export const useUploadFile = () => {
  const queryClient = useQueryClient();
  const access_token = useAccessToken();

  return useMutation<AxiosResponse, AxiosError, TUploadFilePayload>({
    mutationFn: async ({ file, context_uuid, onProgress, isPrivate }) => {
      return uploadFile(
        file,
        access_token || "",
        context_uuid,
        onProgress,
        isPrivate
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["files"],
      });
    },
  });
};

export type TUploadFilesPayload = {
  files: FileList;
  context_uuid: string;
  onProgress: (progress: UploadProgress) => void;
  isPrivate?: boolean;
};

export const useUploadFiles = () => {
  const queryClient = useQueryClient();
  const access_token = useAccessToken();

  return useMutation<AxiosResponse[], AxiosError, TUploadFilesPayload>({
    mutationFn: async ({ files, context_uuid, onProgress, isPrivate }) => {
      return uploadFiles(
        files,
        access_token || "",
        context_uuid,
        onProgress,
        isPrivate
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["files"],
      });
    },
  });
};

type TDeleteFilePayload = {
  uuid: string;
};

export function useDeleteFile() {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();
  const headers = constructHeaders(accessToken || "");
  return useMutation<FileSchemaListResponse, AxiosError, TDeleteFilePayload>({
    mutationFn: async ({ uuid }) => {
      const response = await apiClient.delete(
        `core/files/uuid/${uuid}?permanent=true`,
        {
          headers,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["files"],
      });
    },
  });
}
