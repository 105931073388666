import {
  DeleteButton,
  DownloadButton,
  ViewButton,
} from "~/components/ui/icon-button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { useDeleteFile, useGetFileList } from "~/features/core/api/file-hooks";
import { formatBytes, formatDateTime } from "~/lib/formatter";
import { cn } from "~/lib/utils";

import { FileSchema } from "~/types/backend";

function PreviewFile(props: { file: FileSchema }) {
  return (
    <Popover>
      <PopoverTrigger onClick={(e) => e.stopPropagation()} asChild>
        <ViewButton className="text-transparent group-hover:text-primary-foreground" />
      </PopoverTrigger>
      <PopoverContent className="w-[200px]">
        <img
          src={props.file.download_url || ""}
          width={200}
          height={200}
          alt={props.file.file_name}
        />
      </PopoverContent>
    </Popover>
  );
}

function FileEntity(props: {
  file: FileSchema;
  highlighted?: boolean;
  onClick?: (file: FileSchema) => void;
}) {
  const { file, highlighted, onClick } = props;
  const deleteFileMutation = useDeleteFile();
  return (
    <TableRow
      className={cn(
        "group  text-xs",
        highlighted ? "bg-accent hover:bg-accent" : "hover:bg-muted",
        onClick ? "cursor-pointer" : ""
      )}
      onClick={() => onClick?.(file)}
    >
      <TableCell className="py-0 px-2">
        <div className="flex items-center justify-between">
          <span>{file.file_name}</span>
          <div>
            {file.download_url && file.mimetype?.includes("image") ? (
              <PreviewFile file={file} />
            ) : null}
            {file.download_url ? (
              <a
                href={file.download_url}
                download
                onClick={(e) => e.stopPropagation()}
              >
                <DownloadButton className="text-transparent group-hover:text-primary-foreground" />
              </a>
            ) : null}
            <DeleteButton
              className="text-transparent group-hover:text-destructive-foreground"
              color="destructive"
              onClick={(e) => {
                e.stopPropagation();
                deleteFileMutation.mutate({ uuid: file.uuid });
              }}
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="py-0 px-2">
        {file.created_at ? formatDateTime(file.created_at) : ""}
      </TableCell>
      <TableCell className="py-0 px-2">
        {file.updated_at ? formatDateTime(file.updated_at) : ""}
      </TableCell>
      <TableCell className="py-0 px-2">{formatBytes(file.size)}</TableCell>
      <TableCell className="py-0 px-2">{file.mimetype}</TableCell>
    </TableRow>
  );
}

export function FileTable(props: {
  selectedFile?: FileSchema;
  onSelect?: (file: FileSchema) => void;
}) {
  const { selectedFile, onSelect } = props;
  const { data } = useGetFileList();

  if (!data) return <div>Loading...</div>;
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Created</TableHead>
          <TableHead>Changed</TableHead>
          <TableHead>Size</TableHead>
          <TableHead>Type</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.objects.map((file) => (
          <FileEntity
            file={file}
            key={file.uuid}
            highlighted={file.uuid === selectedFile?.uuid}
            onClick={(file) => onSelect?.(file)}
          />
        ))}
      </TableBody>
    </Table>
  );
}
