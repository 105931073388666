"use client";
import Dropzone from "~/components/ui/dropzone";
import { useUploadFiles } from "~/features/core/api/file-hooks";

export function FileDropzone(props: { context_uuid?: string }) {
  const { context_uuid } = props;
  const uploadFilesMutation = useUploadFiles();
  const handleUpload = (files: FileList | null) => {
    if (!files) return;
    uploadFilesMutation.mutate({
      files,
      context_uuid: context_uuid || "default",
      onProgress: (progress) => {
        console.log(progress);
      },
    });
  };
  return (
    <div className="px-2 py-2">
      <Dropzone
        dropMessage="Drop files or click here"
        handleOnDrop={handleUpload}
      />
    </div>
  );
}
